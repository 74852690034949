<template>
  <v-sheet
    v-if="loading"
    :color="`grey`"
    class="pa-3"
  >
    <v-skeleton-loader
      class="mx-auto"
      type="text-field, table, data-table"
    >
    </v-skeleton-loader>
  </v-sheet>
  <v-card v-else>
    <v-card-title class="text-uppercase font-weight-bold">
      Liste des paiements de deposit traités
    </v-card-title>
    <v-divider></v-divider>
    <br>
    <caution-list
      :data="bondList"
    />
    <!--    <caution-public :data="bondList" v-else-if="bond.customer===null"/>-->
  </v-card>
</template>

<script>
import { onBeforeMount, onMounted, ref } from '@vue/composition-api'
import {
  mdiFileDocumentOutline,
  mdiMagnify,
  mdiHistory,
  mdiMenuDown,
} from '@mdi/js'
import Http from '@/helpers/http'
// eslint-disable-next-line import/extensions
import Liste from '@/views/caution/liste/Liste'
import {BOND_REPOSITORY_NAME} from "@/helpers/caution";

export default {
  components: { CautionList: Liste },

  setup() {
    const bondList = ref([])
    const itemPerPage = 15
    const bond = ref({})

    const loading = ref(true)
    const loadPayment = () => {
      Http.get(
        `list-bond-treated/${JSON.parse(localStorage.getItem('defaultContrat')).id}`,
      ).then(rep => {
        console.log('Réponse : ')
        if (rep.status === 200) {
          const contrat = JSON.parse(localStorage.getItem('defaultContrat'))
          rep.data.forEach(i => {
            const codeClient = (contrat.customer_type_code).split('-')
            const params = { path: `dossiers-clients/${codeClient[0]}/${BOND_REPOSITORY_NAME}/${i.file}` }
            // eslint-disable-next-line no-unused-vars
            Http.post('getFileUrl', params)
              .then(response => {
                // eslint-disable-next-line no-return-assign,no-param-reassign
                i.file_url = response
              })
              .catch(error => {
                console.log(error)
              })
          })

          console.log(rep.data)

        bondList.value = rep.data
        }
        else {
          bondList.value = []
        }

        loading.value = false
      }).catch(error => {
        console.error('Erreur :', error)

        loading.value = false
      })
    }

    const getDeposit = () => {
      Http.get(`get-deposit-payment-information/${JSON.parse(localStorage.getItem('defaultContrat')).id}`)
        .then(response => {
          if (response.status === 200) {
            bond.value = response.data

            console.log('caution information bondParameters')
            console.log(bond.value)
            loadPayment()
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    onBeforeMount(() => {
      // loadPayment()
    })

    return {
      icon: {
        mdiFileDocumentOutline,
        mdiMagnify,
        mdiHistory,
        mdiMenuDown,
      },
      itemPerPage,
      bondList,
      loadPayment,
      getDeposit,
      bond,
      loading,
    }
  },
  created() {
    this.getDeposit()
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/apps/refill.scss';

.custom--icon{
  height: 15px !important;
  width: 15px !important;
  color: #1E9FF2 !important;
}

.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

.border{
  border: #0c5460 solid 1px;
}

.custom-warning--text{
  color: darkorange;
  //background-color: #ffcfcf;
}

.custom-btn:hover{
  background-color: transparent !important;
}
</style>
